import React from 'react';
import { FormattedMessage } from 'react-intl/dist/react-intl';
import { IconCheck } from '../../components';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './ListingPage.module.css';

const getLocationName = key => {
  const filterConfig = config.custom.filters;
  const options = findOptionsForSelectFilter('location', filterConfig);
  const location = options.find(item => item.key === key) || {};
  return location.label || '';
};

const AvailableLocations = props => {
  const { locations = [] } = props;

  return (
    <div className={css.sectionServices}>
      <h2 className={css.servicesTitle}>
        <FormattedMessage id="ListingPage.availableLocations" />
      </h2>
      {locations &&
        locations.map((location, index) => (
          <div key={index}>
            <IconCheck />
            <span>{getLocationName(location)}</span>
          </div>
        ))}
    </div>
  );
};

export default AvailableLocations;
