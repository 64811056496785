import React from 'react';
import { FormattedMessage } from 'react-intl/dist/react-intl';
import { IconCheck } from '../../components';
import css from './ListingPage.module.css';

const AvailableServices = props => {
  const { services } = props;
  return (
    <div className={css.sectionServices}>
      <h2 className={css.servicesTitle}>
        <FormattedMessage id="ListingPage.availableServices" />
      </h2>
      {services &&
        services.map((service, index) => (
          <div key={index}>
            <IconCheck />
            <span>{service.service}</span>
          </div>
        ))}
    </div>
  );
};

export default AvailableServices;
