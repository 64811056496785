import React from 'react';
import { IconReviewStar } from '../../components';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    richTitle,
    reviewsText,
    serviceCategory,
    memberSinceText,
    deliveredServicesNumberText,
    languageSkillsText,
    eduBackgroundText,
    authorNameText,
  } = props;

  const getNameService = key => {
    const filterConfig = config.custom.filters;
    const options = findOptionsForSelectFilter('serviceCategory', filterConfig);
    const service = options.find(item => item.key === key) || {};
    return service.label || '';
  };

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <IconReviewStar isFilled className={css.headingStar} />
        <div className={css.reviewsText}>
          <span>{reviewsText}</span>
        </div>
      </div>
      <div className={css.serviceCategory}>{getNameService(serviceCategory)}</div>
      <div className={css.headingInfor}>{authorNameText}</div>
      <div className={css.headingInfor}>{memberSinceText}</div>
      <div className={css.headingInfor}>{deliveredServicesNumberText}</div>
      <div className={css.headingInfor}>{languageSkillsText}</div>
      <div className={css.headingInfor}>{eduBackgroundText}</div>
    </div>
  );
};

export default SectionHeading;
